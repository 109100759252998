<template>
  <v-container fluid>
    <page-title title="Психологические Студии">
      <template #description>
        <v-card-text
          style="color: black; white-space: pre-line"
          :class="{ 'body-2': $vuetify.breakpoint.xs }"
          class=" headline text-justify body-1"
          ><span>
            Каждая из студий, представленных в этом разделе - это авторская разработка Надежды
            Навроцкой. В студиях вы найдете задания, простые по сути, но глубинные по результату и
            воздействию. Выбирайте тему, в которой вы хотите узнать что-то новое о себе, разрешить
            вопрос, к которому возвращаетесь снова и снова, исследовать свои возможности и
            потенциал. Это расширяет мышление, высвобождает много энергии и креативного потенциала,
            позволяет перейти на качественно новый уровень жизни. Подарите себе возможность увидеть
            ответы, которые уже есть в вас, и получите поддержку и вдохновение для смелых реальных
            шагов в своей жизни.
          </span></v-card-text
        >
      </template></page-title
    >

    <!--    <select-author :list="filterSelect" type-select="authors" />-->
    <select-author :list="listRubrics" type-select="rubrics" />
    <list-component class="studios" :list="modifiedListStudios" @createPath="createPath" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'StudiosList',
  metaInfo: {
    title: 'Психологические Студии',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: `Студии - это игровое пространство для самостоятельного решения психологических проблем`
      }
    ]
  },
  components: {
    ListComponent: () => import('@/components/shared/lists/ListComponent'),
    PageTitle: () => import('@/components/shared/PageTitle'),
    SelectAuthor: () => import('@/components/shared/SelectAuthor')
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      listStudios: state => state.studios.listStudios,
      listAuthors: state => state.shared.listAuthors,
      listRubrics: state => state.shared.listRubrics
    }),
    ...mapGetters(['filterStudioRubrics', 'filterAllListStudios']),
    filterSelect() {
      return this.filterAllListStudios.filter(
        studio => studio.secondType !== 'artTherapy' && studio.secondType !== 'spaceDrawing'
      )
    },
    modifiedListStudios() {
      return this.filterStudioRubrics
        .filter(
          studio => studio.secondType !== 'artTherapy' && studio.secondType !== 'spaceDrawing'
        )
        .map(studio => {
          const dataAuthor = this.listAuthors.find(author => author.id === studio.author)
          return {
            ...studio,
            authorName: `${dataAuthor.firstName} ${dataAuthor.lastName}`
          }
        })
    }
  },
  created() {
    if (this.listStudios.length === 0) this.$store.dispatch('getListStudios', 'studios')
    if (!this.listAuthors.length) this.$store.dispatch('getListAuthors')
  },
  methods: {
    createPath(value) {
      if (this.user.payments && this.user.payments[value]) {
        this.$router.push(`/studios/home/${value}`)
      } else {
        this.$router.push(`/studios/preview/${value}`)
      }
    }
  }
}
</script>

<style scoped lang="stylus" />
